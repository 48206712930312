import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import "../styles/service.css" 

const Service = () => (
  <Layout>
    <div id="serviceContainer">
      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignLeft">
          <p className="singleServiceTitle">Data Consulting</p>
          <p className="singleServiceDescription">We analyze your current workflows and requirements, and figure out best-possible solution to drive better business outcomes.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignRight">
          <p className="singleServiceTitle">Data Preparation</p>
          <p className="singleServiceDescription">We help clients build the infrastructure and architecture for efficient data collection, data storage, data flow, exploration and transformation.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignLeft">
          <p className="singleServiceTitle">Machine Learning Model</p>
          <p className="singleServiceDescription">We dig into your data assignment, design and develop a machine learning model to predict for you or make decision for you.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignRight">
          <p className="singleServiceTitle">Deep Learning Model</p>
          <p className="singleServiceDescription">We build deep learning model to derive insights of data as per your business needs.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignLeft">
          <p className="singleServiceTitle">Predictive Analysis</p>
          <p className="singleServiceDescription"> We develop predictive model to yield data insights that you can use to instantly qualify leads, quantify successes, predict market demand, report pre-failure and failure states, and enable better decision-making.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignRight">
          <p className="singleServiceTitle">Image Analysis</p>
          <p className="singleServiceDescription">We offer image processing to minimize human error with automated visual inspection, facial or emotion recognition, etc.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignLeft">
          <p className="singleServiceTitle">Optimization and Automation</p>
          <p className="singleServiceDescription">We offer comprehensive data science services to optimize repeatable, time-consuming processes and workflows with smart automation. This helps you manage and measure your assets reducing your time and budget resources.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignRight">
          <p className="singleServiceTitle">Implementation and Enhancement</p>
          <p className="singleServiceDescription">We follow state-of-the-art technology, techniques and knowledge of data science for the implementation of your data assignment. Also, we manage, optimize and expand existing capabilities of functionalities.</p>
        </div>
      </div>

      <div className="singleServiceContainer">
        <div className="singleServiceInfo singleServiceAlignLeft">
          <p className="singleServiceTitle">Training and Mentoring</p>
          <p className="singleServiceDescription">We provide extensive training services on data science and technologies according to your demands. We design the training module following current industry practiced tools and technologies.</p>
        </div>
      </div>
    </div>
  </Layout>
)

export const Head = () => <Seo title="Service" />

export default Service
